export default function ({ store }) {
  if (process.client) {
    requestAnimationFrame(() => {
      const member = sessionStorage.getItem('member')
      if (member) {
        const parseData = JSON.parse(member)
        setTimeout(() => {
          parseData.id
            ? store.dispatch('authentication/updateMembersHeader', true)
            : store.dispatch('authentication/updateMembersHeader', false)
        }, 0)
      }
    })
  }
}
