
import { Component, Prop, Vue } from 'vue-property-decorator'
import MixinMemberId from '~/components/mixins/MixinMemberId'

@Component({
  mixins: [MixinMemberId]
})
export default class GlobalMenu extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private used!: string

  private memberId!: number
  private isMobile: boolean = false // スマホ表示かどうかを示すフラグ

  private memberMenu = [
    {
      nameJa: 'マイページ',
      link: '/members/mypage'
    },
    {
      nameJa: '新着記事',
      link: '/contents'
    },
    {
      nameJa: '企業検索',
      link: '/companies'
    },
    {
      nameJa: '特集',
      link: '/feature'
    }
  ]

  private defaultMenu = [
    {
      nameJa: 'はじめての方へ',
      link: '/talentbook_guide'
    },
    {
      nameJa: '新着記事',
      link: '/contents'
    },
    {
      nameJa: '企業検索',
      link: '/companies'
    },
    {
      nameJa: '特集',
      link: '/feature'
    }
  ]

  private get gnavList() {
    if (this.isMobile) {
      return this.defaultMenu
    } else if (this.memberId && this.used === 'sidenav') {
      return this.memberMenu.filter((item) => item.nameJa !== 'マイページ')
    } else if (this.memberId) {
      return this.memberMenu
    } else {
      return this.defaultMenu
    }
  }

  private get auth() {
    return this.memberId
  }

  private closeMenu() {
    this.$emit('close-menu')
  }

  private get activeLinks() {
    return this.gnavList.map((nav) => ({
      ...nav,
      isActive: this.$route.path === nav.link
    }))
  }

  private mounted() {
    this.checkWindowWidth()
    window.addEventListener('resize', this.checkWindowWidth)
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowWidth)
  }

  private checkWindowWidth() {
    this.isMobile = window.innerWidth <= 768
  }

  private getNavLinkClass(gnav: { link: string; isActive: boolean }) {
    return [
      'header-nav-item',
      {
        active: gnav.isActive,
        'button-style': gnav.link === '/talentbook_guide'
      }
    ]
  }
}
