import { reportError } from '~/plugins/karte-error'

export function clickItemKarte(trackingData) {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'staging'
  )
    return

  try {
    if (window.krt) {
      window.krt('send', 'click_item_detail', {
        title: trackingData.title,
        name: trackingData.name,
        url: trackingData.url,
        link: trackingData.link
      })
    }
  } catch (e) {
    reportError(e)
  }
}
