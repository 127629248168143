
import { Component, Vue } from 'vue-property-decorator'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import UniversalCookie from 'universal-cookie'
import HeaderLogo from '~/components/layouts/header/HeaderLogo.vue'
import GlobalMenu from '~/components/layouts/header/menu/GlobalMenu.vue'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import SideNav from '~/components/layouts/SideNav.vue'
import HeaderNotification from '~/components/layouts/header/HeaderNotification.vue'
import HeaderNavigationMenu from '~/components/layouts/header/menu/HeaderNavigationMenu.vue'
import HeaderMemberNavigationMenuMobile from '~/components/layouts/header/menu/HeaderMemberNavigationMenuMobile.vue'
import SearchInputSuggestions from '~/components/shared/SearchInputSuggestions.vue'
import authSetting from '~/config/authSetting'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import MixinLogout from '~/components/mixins/MixinLogout'
import { API } from '~/types/api'

@Component({
  components: {
    HeaderLogo,
    GlobalMenu,
    LogoBtn,
    SideNav,
    HeaderNotification,
    HeaderNavigationMenu,
    HeaderMemberNavigationMenuMobile,
    SearchInputSuggestions
  },
  async fetch() {
    await this.$store.dispatch(
      'time/getServerTimestamp',
      this.$fetchState.timestamp
    )
    await this.$store.dispatch('search/getSuggestData')
    await this.$store.dispatch('tags/getAllTags', this.$nuxt.context)
  },
  mixins: [MixinMemberId, MixinLogout]
})
export default class Header extends Vue {
  private memberId!: number
  private searchWord = ''
  private showSearch = false

  private get auth() {
    return this.$store.state.authentication.membersHeader
  }

  private submitSearch() {
    if (this.searchWord.trim()) {
      // URLにqueryパラメータを付けて遷移
      const encodedQuery = encodeURIComponent(this.searchWord.trim())
      const searchUrl = `/search?query=${encodedQuery}`
      // 検索inputをクリア
      this.searchWord = ''
      this.showSearch = false
      this.$router.push(searchUrl)
    }
  }

  private get confirmPath() {
    return this.$route.path === '/'
  }

  private async errorLogout() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.errorLogoutAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private async errorLogoutAction(csrf: string) {
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/`
          location.href = redirectUrl
        })
    } catch (e) {
      return e
    }
  }

  private memberMenuOpend = false

  private async logout() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.logoutAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private get spMenu() {
    return this.$store.state.spmenu.spMenuActive
  }

  private selectMenu() {
    this.$store.dispatch('spmenu/setMenuActive', this.spMenu)
    const spmenu = document.querySelector('.nav-menu')
    if (this.spMenu) {
      disableBodyScroll(spmenu)
      spmenu.scrollTop = 0
    } else {
      clearAllBodyScrollLocks()
    }
  }

  private async logoutAction(csrf: string) {
    const cookies = new UniversalCookie()
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // API401の際にsetされるcookie削除
          cookies.remove('unauthorized_error')
          // 退会完了時にsetされるcookie削除
          cookies.remove('quitMessage')
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/login?logout=true`
          location.href = redirectUrl
        })
    } catch (e) {
      return e
    }
  }

  private handleCloseMenu() {
    this.memberMenuOpend = false
    this.$forceUpdate()
  }
}
