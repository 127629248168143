import { Middleware } from '@nuxt/types'

const commonInfoMiddleware: Middleware = (context) => {
  const { store, route } = context

  // 除外するURL
  const excludedPaths = [
    /^\/preview\/stories\/[\w-]+$/,
    /^\/preview\/knowhows\/[\w-]+$/
  ]

  // 現在のルートが除外urlに一致する場合はスキップ
  if (excludedPaths.some((pattern) => pattern.test(route.path))) {
    return
  }

  // preview の場合は params.id を companyId として使用
  const isPreview = route.path.includes('/preview/')
  const companyId = isPreview ? route.params.id : route.params.companyId

  // companyId が存在しない場合はスキップ
  if (!companyId) return

  try {
    const currentCompanyInfo = store.state.companies.companyInfo
    // 現在の企業情報と一致する場合はスキップ
    if (currentCompanyInfo && currentCompanyInfo.openUrl === companyId) return

    store.dispatch('companies/fetchCompanyInfo', { companyId, context })
  } catch (error) {
    throw new Error(`Failed to fetch company info: ${(error as Error).message}`)
  }
}

export default commonInfoMiddleware
