import initializeMunchkin from '../lib/munchkin'

// Marketo の対象ページを定義
// 万一対象企業が増えた場合には object 追加
// ただし、TAGS.pathRegexpのpathを踏むと以降のINPは不良となる(Munchkin.initの影響)
const TAGS = [
  {
    pathRegexp: /^\/shopro/,
    tagId: '153-JDX-787'
  }
]

function pathToTagId(path) {
  const tag = TAGS.find((tag) => tag.pathRegexp.test(path))
  return tag ? tag.tagId : null
}

// Munchkin の初期化が済んでいるかチェック
let isMunchkinInitialized = false

export default ({ app }) => {
  if (process.env.NODE_ENV !== 'production') return

  app.router.afterEach((to, _from) => {
    const tagId = pathToTagId(to.path)

    // `/shopro` 配下でのみ実行
    if (tagId) {
      if (!isMunchkinInitialized) {
        // Munchkin の初回ロード時に一度だけ初期化
        initializeMunchkin(window)
        isMunchkinInitialized = true
      }
      // 対象企業関連ページの場合、遷移の度にMunchkinを毎回初期化し、パスごとの正しいタグIDで設定し直す。
      // vue-routerのafterEachが呼ばれるタイミングでは、Munchkinの中で呼んでいるlocation.pathnameがまだ新しいパスに切り替わっておらず、
      // リクエストのパラメータのパスの値が古いパスになってしまうため、少し待ってからinitを呼び出す。
      setTimeout(() => initMunchkin(tagId), 1000)
    }
  })
}

// Munchkin init
const initMunchkin = (tagId) => {
  if (!tagId || !window.Munchkin || typeof window.Munchkin.init !== 'function')
    return
  // MunchkinのタグIDを適用
  window.Munchkin.init(tagId)
}
