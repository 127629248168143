
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SearchInputSuggestions extends Vue {
  @Prop({ type: String, required: false })
  private used: string

  private searchWord: string = ''
  private menu: boolean = false

  private suggestions: { companies: Array<any>; categories: Array<any> } = {
    companies: [],
    categories: []
  }

  private updateSuggestions() {
    if (this.searchWord.trim() === '') {
      // this.searchWordが空の場合はサジェストをクリア
      this.suggestions = { companies: [], categories: [] }
      this.menu = false
    } else {
      // this.searchWordが入力されたらサジェストを更新し、メニューを開く
      this.suggestions = this.$store.getters['search/filteredSuggestions'](
        this.searchWord
      )
      this.menu =
        this.suggestions.companies.length > 0 ||
        this.suggestions.categories.length > 0
    }
  }

  private handleInput() {
    if (this.searchWord.trim() === '') {
      this.menu = false
    } else {
      this.updateSuggestions()
      this.menu = true
    }
  }

  private closeMenu() {
    this.menu = false
  }

  private submitSearch() {
    if (this.searchWord) {
      const encodedQuery = encodeURIComponent(this.searchWord.trim())
      this.resetSearchWord()
      this.$router.push(`/search?query=${encodedQuery}`)
    }
  }

  private resetSearchWord() {
    this.searchWord = ''
  }
}
